import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StoreService, LayoutUtilsService, RequestService } from '../../services';
import { urlSafeBase64Encoding } from '../../helpers';

export interface ResetPasswordDialogData {

	disableClose: boolean ;

}


@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  @ViewChild('wrapper') wrapperDiv: ElementRef;

  public accountDataForm: FormGroup

  public userObj: any = {};
  public loading = false;

  public formChangeSubscription: Subscription;
  public invalidForm: boolean = false;
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  constructor(
    private storeService: StoreService,
    private layoutUtilService: LayoutUtilsService,
    private router: Router,
    public fb: FormBuilder,
    private requestService: RequestService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ResetPasswordDialogData,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,

  ) {

    dialogRef.disableClose = this.data.disableClose;


  }

  ngOnInit(): void {
    this.loadData()
  }

  private loadData() {
    this.accountDataForm = this.fb.group({
      old_password: ['', [Validators.required, this.compareOldNewPasswords]],
      new_password: ['', [Validators.required, this.compareOldNewPasswords, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
      confirm_password: ['', [Validators.required, this.checkPasswords]]
    })
  }

  public compareOldNewPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = this.accountDataForm?.controls.new_password.value;
    let oldPass = this.accountDataForm?.controls.old_password.value
    console.log(pass === oldPass && pass?.length > 0 && oldPass?.length > 0)
    return pass === oldPass && pass?.length > 0 && oldPass?.length > 0 ? { same: true } : null;
  }

  public checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = this.accountDataForm?.controls.new_password.value;
    let confirmPass = this.accountDataForm?.controls.confirm_password.value;
    if (pass === confirmPass || (confirmPass?.length === 0 || pass?.length === 0))
      return null;
    else {
      return { notSame: true };
    }
  }

  public onClose() {
    this.dialogRef.close(undefined)
  }


  public onSubmit() {
    this.invalidForm = false;
    this.loading = true;
    let form = this.accountDataForm;
    // form.markAllAsTouched();
    let valid = form.valid;

    if (!valid) {
      this.invalidForm = true;
      this.loading = false;
      return;
    }

    if (form.controls.new_password.value != form.controls.confirm_password.value) {
      form.controls.confirm_password.setErrors({ notSame: true });
      this.loading = false;
      return
    }

    let bodyObj = {
      oldpassword: form.controls.old_password.value,
      newpassword: form.controls.new_password.value
    }
    this.requestService.passwordReset(bodyObj, (res, err) => {
      this.loading = false;
      if (err) {
        this.dialogRef.close({ type: false, message: err.message });
        return;
      }
      else {
        if (res) {
          if (res.status == false) {

            this.layoutUtilService.showNotification(res.message, 'Dismiss');


            //this.dialogRef.close({ type: false, message: res.message });

          }
          else {
            this.layoutUtilService.showNotification(res.results, 'Dismiss');

            this.dialogRef.close({ type: true, message: res.message });
            return;
          }
        }
        else
          this.dialogRef.close({ type: false, message: 'server error' });
      }
    })
  }

}
