import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'dashboard',
					page: '/dashboard',
					bullet: 'dot',
				},
				{
					title: 'Notification',
					root: true,
					icon: 'notes',
					page: '/notification',
					bullet: 'dot'
				},
				{
					title: 'Video Tutorials',
					root: true,
					icon: 'videocam',
					page: '/videoTutorials',
					bullet: 'dot'
				}
			]
		},
	};

	public get configs(): any {

		
		return this.defaults;
	}
}
