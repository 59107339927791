<div class="container">
    <div class="wrapper" [formGroup]="accountDataForm" #wrapper>
        <div class="content-header">
            <h2 class="page-title">Change password</h2>
        </div>
        <div fxLayout="column" fxLayoutGap="0.5vw">
            <mat-error
                    *ngIf="data.disableClose">
                    Your password has expired. Please change your password in order to log in
            </mat-error>
            <mat-form-field fxFlex>
                <mat-label>Old Password <sup>*</sup></mat-label>
                <input matInput name ='old_password' autocomplete="off" [type]="showOldPassword ? 'text' : 'password'" formControlName="old_password">
                <mat-icon *ngIf="showOldPassword" class="password-eye" (click)="showOldPassword = !showOldPassword">
                    visibility</mat-icon>
                <mat-icon *ngIf="!showOldPassword" class="password-eye" (click)="showOldPassword = !showOldPassword">
                    visibility_off</mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>New Password <sup>*</sup></mat-label>
                <input matInput name ='new_password' autocomplete="off" [type]="showNewPassword ? 'text' : 'password'" formControlName="new_password">
                <mat-icon *ngIf="showNewPassword" class="password-eye" (click)="showNewPassword = !showNewPassword">
                    visibility</mat-icon>
                <mat-icon *ngIf="!showNewPassword" class="password-eye" (click)="showNewPassword = !showNewPassword">
                    visibility_off</mat-icon>
                <mat-error *ngIf="accountDataForm.controls.new_password.errors?.pattern">
                    The password you entered does not meet the minimum security requirements.
                </mat-error>
                <mat-error
                    *ngIf="accountDataForm.controls.new_password.errors?.hasOwnProperty('same') && accountDataForm.controls.new_password.errors.same !== null">
                    The password you entered is the same as the old password. Please enter a new password.
                </mat-error>
                <mat-hint>Your password must contain at least 8 characters including lower and upper case letters,
                    numbers, and special characters.</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Confirm Password <sup>*</sup></mat-label>
                <input matInput  name ='confirm_password'autocomplete="off" [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirm_password">
                <mat-icon *ngIf="showConfirmPassword" class="password-eye"
                    (click)="showConfirmPassword = !showConfirmPassword">
                    visibility</mat-icon>
                <mat-icon *ngIf="!showConfirmPassword" class="password-eye"
                    (click)="showConfirmPassword = !showConfirmPassword">
                    visibility_off</mat-icon>
                <mat-error>
                    The passwords you entered do not match.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-actions">
            <button mat-raised-button color="danger" *ngIf="!data.disableClose"(click)="onClose()" type="button">Close</button>
            <button mat-raised-button (click)="onSubmit()" [disabled]="false"  type="button">Submit</button>
        </div>
    </div>

</div>