<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
				</div>
			</div>
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					<mat-form-field class="example-full-width">
	          <input matInput [(ngModel)]="data.data" placeholder="{{data.placeholder}}" >
	        </mat-form-field>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br/>
				<button mat-raised-button (click)="onNoClick()">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button color="danger" (click)="onYesClick()" color="primary" cdkFocusInitial>{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>
