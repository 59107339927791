import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { GlobalService } from './services/global.service';

import { SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent ,ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, AlertActionEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ConfirmInputEntityDialogComponent} from './components';

import { ModalFieldCropDialogComponent,ModalGalleryDialogComponent,ModalPasswordDialogComponent, ResetPasswordDialogComponent, ModalUserViewDialogComponent } from './components';

import { ModalDialDialogComponent, ModalEmailDialogComponent, ModalVideoDialogComponent, ModalPagesDialogComponent, ModalEventMediaDialogComponent, ModalFormMediaDialogComponent, ModalEventDocumentDialogComponent, ModalCalenderDialogComponent, ModalPreviewDialogComponent, ModalBackgroundDialogComponent, CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent } from './components';
import { ClickOutSideDirective } from './directives';

import { Utils } from './helpers/utils';
import { CKEditorModule } from 'ngx-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({addFlexToParent: false})
    ],
    providers: [
        // GlobalService
        Utils
    ],
    entryComponents: [
        SnackBarComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalIframeDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        LoadingScreenComponent,
        ModalViewDialogComponent,
        EnumViewComponent,
        ModalGalleryDialogComponent,
        ModalPasswordDialogComponent,
        ResetPasswordDialogComponent,
        ModalUserViewDialogComponent,
        ModalDialDialogComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        ModalPreviewDialogComponent,
        ModalBackgroundDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        ModalFieldCropDialogComponent
    ],
    declarations: [
        SidebarComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        EnumViewComponent,
        ActionNotificationComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalIframeDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        LoadingScreenComponent,
        ModalGalleryDialogComponent,
        ModalPasswordDialogComponent,
        ResetPasswordDialogComponent,
        ModalUserViewDialogComponent,
        ModalDialDialogComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        ModalPreviewDialogComponent,
        ModalBackgroundDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        ClickOutSideDirective,
        ModalFieldCropDialogComponent
    ],
    exports: [
        SidebarComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalIframeDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalGalleryDialogComponent,
        ModalPasswordDialogComponent,
        ResetPasswordDialogComponent,
        ModalUserViewDialogComponent,
        ModalDialDialogComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        ModalPreviewDialogComponent,
        ModalBackgroundDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        ClickOutSideDirective,
        ModalFieldCropDialogComponent
    ]
})
export class LayoutComponentModule { }
