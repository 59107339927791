import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-password-dialog-modal',
	templateUrl: './custom-password-dialog.component.html',
	styleUrls: ['./custom-password-dialog.component.scss']
})
export class ModalPasswordDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public password: string = '';
	public oldpassword: string = '';
	public confirmpassword: string = '';
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalPasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		// console.log('UserViewDialogData', data);
	}
	ngOnInit() {
		
	}
	public saveData() {
		if (this.password !== '' && this.oldpassword !== '') {
			if (this.password === this.confirmpassword) {
				if (!this.loading) {
					this.loading = true;
					this.errorMessage = '';
					this.requestService.editUserPassword(this.password, (data, error) => {
						if (error) {
							this.errorMessage = error;
							this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
						}
						if (data) {
							this.layoutUtilsService.showNotification(this.translate.instant('Password changed successfully'), this.translate.instant('Dismiss'));
							this.closeModal(true);
						}
						this.loading = false;
					});
				}
			} else {
				this.layoutUtilsService.showNotification(this.translate.instant('The entered passwords do not match'), this.translate.instant('Dismiss'));
			}
		} else {
			this.layoutUtilsService.showNotification(this.translate.instant('Fill in the fields'), this.translate.instant('Dismiss'));
		}
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
