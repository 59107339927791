<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
    <mat-card>
			<mat-card-content>
        <div class="form-group">
					<mat-form-field>
						<input matInput autocomplete="new-password" type="password" name="oldpassword" [(ngModel)]="oldpassword" placeholder="{{'Old Password' | translate}}" >
					</mat-form-field>
					<mat-form-field>
						<input matInput type="password" name="password" [(ngModel)]="password" placeholder="{{'New Password' | translate}}" >
					</mat-form-field>
					<mat-form-field>
						<input matInput type="password" name="confirmpassword" [(ngModel)]="confirmpassword" placeholder="{{'Confirm New Password' | translate}}" >
					</mat-form-field>
				</div>
			</mat-card-content>
    </mat-card>
	</div>
	<div mat-dialog-actions>
			<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button [disabled]="loading" (click)="saveData()" cdkFocusInitial>{{'Save' | translate}}</button>
	</div>
	<br/>
</div>
