import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { ModalFieldCropDialogComponent } from '../../../shared/components/custom-field-crop-dialog/custom-field-crop-dialog.component';

import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public pushRightClass: string;
  public selectedOrganization: string = '';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public selectedDatafilters: any = undefined;
  public ThemeColor: string = "primary";
  public LogoImage: string = "assets/images/logo.png";
  public consultationImage: string = "assets/images/consultation_mybackPathways.png";

  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  constructor(private requestService: RequestService,
    private storeService: StoreService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.setThemeColors();

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
        
          let orgsList = this.requestService.getListContains(this.selectedUser['organizationId'], '_id');
          let filter = { '$and': [] };
          filter['$and'].push({ '_id': { '$in': orgsList } });
          this.selectedDatafilters = filter;
          if (this.selectedUser && this.selectedUser.hasOwnProperty('organizationId') && this.selectedUser['organizationId'].length > 0) {
          var host = window.location.host;
          var loginPage = host.split(".")[0];
          if (loginPage == "mybackpathways") {
            const myBackPathwaysRecord = this.selectedUser['organizationId'].find( ({ _id }) => _id === '5b106165ecedfa82a7dd46ed');
            //console.log("test",result);
            if (myBackPathwaysRecord !== undefined){
              this.setSelectedOrganization(myBackPathwaysRecord, false);

            }
            else {
              this.setSelectedOrganization(this.selectedUser['organizationId'][0], false);
            }
           // console.log("first element",this.selectedUser['organizationId'][0]);

          }

         else {

            this.setSelectedOrganization(this.selectedUser['organizationId'][0], false);

         }
            
            
          }
        }

      })
    );
    this.subscriptions.push(
      this.requestService.orgId.subscribe((data) => {
        if (data) {
          this.selectedOrganization = data;
        }
      })
    );
  }
  goToConsultationLink() {

    // window.open("https://mybackpathways.com/tutorials", "_blank");

  }
  goToLink() {
    var host = window.location.host;
    var loginPage = host.split(".")[0];
    if (loginPage == "mybackpathways") {

      window.open("https://mybackpathways.com/tutorials", "_blank");

    }

  }

  setThemeColors() {

    var host = window.location.host;

    if (host == "localhost:4200" || host == "patient-dashboard.interactivelife.me" || host == "patient.ili.ms") {
      this.ThemeColor = "primary";
      this.LogoImage = "assets/images/logo.png";

      (document.querySelector('#consultationImgContainer') as HTMLElement).style.display = 'none';

    }
    else {

      var orgLoginPage = window.location.host.split('.')[0];

      this.ThemeColor = "primary-" + orgLoginPage;

      this.LogoImage = "assets/images/topNavLogo_" + orgLoginPage + ".png";

      (document.querySelector('#topNavLogo') as HTMLElement).style.cursor = 'pointer';

      (document.querySelector('#OrgDropDownContainer') as HTMLElement).style.display = 'none';


    }



  }

  setSelectedOrganization(e, reload = true) {

    this.requestService.orgId.next(e._id);
    this.storeService.set('orgId', e._id);
    // if(reload)
    //   window.location.reload();
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    this.requestService.logout();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }

  openUpload() {
    const dialogRef = this.dialog.open(ModalFieldCropDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.dataTypeDisplay,
        title: this.translate.instant('Upload') + ' ' + this.translate.instant('patient') + ' ' + this.translate.instant('Image'),
        data: { _id: this.selectedUser._id },
        currentImage: this.selectedUser["profile"],
        variable: 'profile',
        targetApi: 'images',
        maxHeight: 3000,
        maxWidth: 3000,
        targetDeleteApi: 'imagedelete',
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1000px',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

}
