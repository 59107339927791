// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverMainUrl: 'https://patient-dashboard.interactivelife.me/',
  serverUrl: 'https://api.staging.ili.ms/v2/',
  // serverUrl: 'https://staging.ili.ms/api/',
  serverName: 'Patient Dashboard',
  subServerName: '',
  profileImageURL: '',
  orgType: '',
  stripe_links: {
    monthly: 'https://buy.stripe.com/test_3csaFK6Vkdgxdd6144',
    yearly: 'https://buy.stripe.com/test_aEU6puenMgsJb4Y001'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
