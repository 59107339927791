import { Component, Inject,ChangeDetectorRef, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalPasswordDialogComponent } from '../../../shared/components/custom-password-dialog/custom-password-dialog.component';
import { ResetPasswordDialogComponent } from '../../../shared/components/reset-password-dialog/reset-password-dialog.component';

import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ModalFieldCropDialogComponent } from '../../../shared/components/custom-field-crop-dialog/custom-field-crop-dialog.component';


export interface UserViewDialogData {
	dataType: string;
	title: string;
	data: any;
	profile: boolean;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-user-view-dialog-modal',
	templateUrl: './custom-user-view-dialog.component.html',
  styleUrls: ['./custom-user-view-dialog.component.scss']
})
export class ModalUserViewDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
  public scopes: string[] = [];
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public pictureLink: string = 'assets/images/profile.png';
  public fieldsDetails: any = undefined;
	public permission: any[] = [];
	public metaFieldTabsSetting = [];
	public tableDataTemp: any = undefined;
	public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea'];

	constructor(
		public dialog: MatDialog, private translate: TranslateService,
		private requestService: RequestService,
		private changeDetectorRefs: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalUserViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: UserViewDialogData) {
			// console.log('UserViewDialogData', data);
	}

	ngOnInit() {
		this.buildSetting();
	}

	openUpload(){
		
		const dialogRef = this.dialog.open(ModalFieldCropDialogComponent, {
			width: '800px',
			data: {
			  dataType: this.data.dataType,
			  dataTypeTitle: " ",
			  title: this.translate.instant('Upload') + ' ' + this.translate.instant('provider') + ' ' + this.translate.instant('Image'),
			  data: { _id: this.data.data._id },
			  currentImage: this.data.confirmData.profile,
			  variable: 'profile',
			  targetApi: 'images',
			  maxHeight: 3000,
			  maxWidth: 3000,
			  targetDeleteApi: 'imagedelete',
			  modalSetting: Object.assign({}, this.data.modalSetting)
			}
		  });
		  dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (result['status']) {
					this.data.confirmData = result.results;
					this.pictureLink = result.results["profile"];
				}

				this.changeDetectorRefs.detectChanges();
			  //this.custom_metadata_table.loadData();
			}
		  });
	}

	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.data.modalSetting = data.results;
					this.fieldsDetails = this.buildMetaSetting(data.results);
					console.log('this.fieldsDetails', this.fieldsDetails);
					if(this.data.data.hasOwnProperty('_id')){
						this.loadData();
					}
				} else {
					this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
				}
			});
		}
	}
	public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
					this.tableDataTemp = Object.assign({}, data.results);
					this.data.confirmData = Object.assign({}, data.results);
					console.log('this.data.confirmData ', this.data.confirmData);
					if(data.results['profile']){
						this.pictureLink = data.results['profile'];
					 }
        }
        this.loading = false;
      });
    }
  }
  convertArrayToObject(arr: any[]){
    let obj = {};
    for(let a of arr){
      obj[a._id] = a;
    }
    return obj;
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
	buildMetaSetting(data, parent = undefined){
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields){
			if((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table'){
				if(parent){
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			}else if (col.type === 'object'){
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
			}else if (col.type === 'table'){
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}

	changePassword() {
		const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
		  width: '550px',
		  data: {
			disableClose: false,
		  }
		  
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res?.message)
			this.layoutUtilsService.showNotification(res.message, 'Dismiss');

		});
	  }

	resetPassword() {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        width: '300px',
        data: {
          title: this.translate.instant('Reset Password'),
          description: this.translate.instant('Are you sure you want to reset this user password?'),
          cancelbtn: this.translate.instant('Close'),
          confirmbtn: this.translate.instant('Reset')
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
        if(result){
          this.confirmResetPassword();
        }
      }
    });
  }
  confirmResetPassword() {
    if (!this.loading) {
        this.loading = true;
        this.errorMessage = '';
        this.requestService.resetUserPassword( this.data.data['_id'], (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            this.layoutUtilsService.showNotification(this.translate.instant('Password Reset Confirmed'), this.translate.instant('Dismiss'));
          }
          this.loading = false;
        });
    }
  }
	public buildTabsMetaSetting() {
		if(this.data.confirmData){
			for (let tab of this.metaFieldTabsSetting){
				if(!this.tableDataTemp.hasOwnProperty(tab.name)){
					if(this.data.confirmData.hasOwnProperty(tab.name)){
						delete this.data.confirmData[tab.name];
					}
				}
				if(this.data.confirmData.hasOwnProperty(tab.name) || this.data.confirmData['role']['targetfield'] === tab.name){
					if(!this.data.confirmData.hasOwnProperty(tab.name)){
						this.data.confirmData[tab.name] = {}
					}
				}
			}
		}
	}
	public viewTranscript(transcript: any){
		window.open(transcript.fileLink, '_blank');
	}
}
