<div class="loading-screen-wrapper" *ngIf="showLoader">
  <div class="loading-screen-icon">
    <svg>
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#8721b1;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#393a3e;stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</div>
