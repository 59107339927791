import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
// Object path
import * as objectPath from 'object-path';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public showMenu: string;
  public menuConfigs: any[] = [];

  constructor(private requestService: RequestService, private menuConfigService: MenuConfigService) {
    this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
  }

  ngOnInit() {


    var host = window.location.host;
    var loginPage = host.split(".")[0];

    if (loginPage != "mybackpathways") {
      const indexOfObject = this.menuConfigs.findIndex((object) => {
        return object.page === '/videoTutorials';
      });

      if (indexOfObject !== -1) {
        this.menuConfigs.splice(indexOfObject, 1);
      }
    }

    this.subscriptions.push(
      this.menuConfigService.onConfigUpdated$.subscribe((data) => {
        if (data) {
          this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
        }
      })
    );
    this.showMenu = 'admin';
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  clickingOutside() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('push-right');
  }
  hideMenu() {

    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';

    this.toggleSidebar();

    var frame = document.getElementById('PatientDashboardIframe');

    if (isIFrame(frame) && frame.contentWindow) {
      var isProcedureContainerHidden = (frame.contentWindow.document.getElementById('procedureContainer')).style.display;


      if (isProcedureContainerHidden != 'none') {

        (frame.contentWindow.document.getElementById('backButton')).click();
      }


    }

  }
  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('push-right');
  }
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
}
